import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as Datepicker from 'vuejs-datepicker';
import { IProgramPlanner } from '@/Model/programModel';
import programPlanner from '@/store/modules/programPlannerStore';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import {downloadFile} from '@/services/program/programPlannerService';

@Component({
  components: {
    'datepicker': Datepicker.default
  }
})
export default class ReadOnlyTaskComponent extends Vue {
    @Prop()
    plannerTaskObj!: IProgramPlanner;
    private objScreenText: ScreenText = new ScreenText();
    public toggleButton: boolean = false;
    
    @Prop()
    toggleMenu: boolean = false;

    @Prop()
    roleId !: number;  
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    @Watch('toggleMenu')
    toogleCheck() {
      this.toggleButton = this.toggleMenu;
    }

    toggle() {
      this.toggleButton = !this.toggleButton;
    }

    editTask() {
      this.toggleButton = false;
      this.$emit(APP_CONST.OPEN_UPDATE_TASK_SCREEN, this.plannerTaskObj);
    }

    updateState() {        
      this.plannerTaskObj.createdAt = new Date() as any;
      const ObjPlannerTask = JSON.parse(JSON.stringify(this.plannerTaskObj));
      const state = this.plannerTaskObj.status.state;
      ObjPlannerTask.startDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.startDate) as any;
      ObjPlannerTask.endDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.endDate) as any;
      ObjPlannerTask.status.state = state === APP_CONST.DONE_STATUS
        ? APP_CONST.ACTIVE_STATUS
        : APP_CONST.DONE_STATUS;
      ObjPlannerTask.status.id = state === APP_CONST.DONE_STATUS
        ? APP_CONST.ONE
        : APP_CONST.FOUR;
      ObjPlannerTask.productProgramId = APP_CONST.ONE as any;
      if(this.plannerTaskObj.isRemoved){
        ObjPlannerTask.isRemoved = false;    
      }
      else{
        ObjPlannerTask.isRemoved = false;
      }
      programPlanner.updateProgramPlannerTask(ObjPlannerTask).then(() => {
        if (programPlanner.updatedPlanTaskData.status === APP_CONST.RESPONSE_200) {
          this.toggleButton = false;
          if(this.plannerTaskObj.isRemoved){
            this.$emit(APP_CONST.UPDATE_TASK, { ObjPlannerTask : this.plannerTaskObj, isFirstUpdated: programPlanner.updatedPlanTaskData.data.isFirstUpdated , state: APP_CONST.UPDATE_TASK });
          }
          else{
            this.$emit(APP_CONST.UPDATE_TASK, { ObjPlannerTask, isFirstUpdated: programPlanner.updatedPlanTaskData.data.isFirstUpdated , state: APP_CONST.UPDATE_TASK });
          }
          this.plannerTaskObj.createdAt = new Date() as any;
        }
      });
    }

    DateFormat(data: string) {
      const date = APP_UTILITIES.formatShortDate(data);
      return date;
    }

    back() {
      this.$emit(APP_CONST.BACK_BUTTON);
      this.toggleButton = false;
    }

    deleteTask() {
      const objToDlt = { ...this.plannerTaskObj };
      programPlanner.deleteTask(objToDlt).then((data : any) => {
        if (data.status === APP_CONST.RESPONSE_200) {
          const ObjPlannerTask = objToDlt;
          this.toggleButton = false;
          this.$emit(APP_CONST.DELETE_TASK, { ...ObjPlannerTask , state: 'deleteTask' });
          this.plannerTaskObj.createdAt = new Date() as any;
        }
      });
    }

    getDocType(url : string) {
      if(url) {
        const filetype = url.split('.');        
        return'('+filetype[filetype.length-1].toUpperCase()+')';
      }
      else {
        return '';
      }
       
    }
   
    forceFileDownload(resource : any){
      if(resource.fileType === '1') {
        window.open(resource.jwtUrl,'_blank');
      }
      else {
        const fileName = resource.url.split('/');
        downloadFile(resource.url).then((response : any) => {
          if(response.status === APP_CONST.RESPONSE_200) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName[fileName.length-1]);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
      } 
    }
}