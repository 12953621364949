import { httpCMSClient, httpPlannerClient, httpEventClient, httpResource } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import { IProgramPlanner, IProgramEvent } from '@/Model/programModel';
import programConfigure from '@/store/modules/programConfigure';

export function getPlannerData(args: any) { 
  return httpPlannerClient().get(`${API_CONST.PROGRAM_PLAN_ENDPOINT}?programId=${args.programId}&productId=1&siteId=${args.siteId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getEventPlannerData(args: any) {
  return httpPlannerClient().get(`${API_CONST.PROGRAM_PLAN_EVENT_ENDPOINT}?programId=${args.programId}&siteId=${args.siteId}&productid=1`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function createProgramPlanTask(payload: IProgramPlanner) {
  return httpCMSClient().post(`${API_CONST.CREATE_PROGRAM_TASK}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });

}

export function updateProgramPlanTask(payload: IProgramPlanner) {
  payload.productId = programConfigure.productId;
  return httpPlannerClient().put(`${API_CONST.UPDATE_PROGRAM_TASK}/${payload.id}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function addPlanEvent(payload:IProgramEvent){
  return httpEventClient().post('',payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}



export function updatePlanEvent(payload:IProgramEvent){
  return httpEventClient().put(`/${payload.id}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };  
  });
}


export function deletedPlanEvent(payload:number){
  return httpPlannerClient().delete(`${API_CONST.DELETE_EVENT}/${payload}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };  
  });
}

export function deletedPlanTask(payload:any){
  return httpPlannerClient().delete(`${API_CONST.DELETE_TASK}/${payload.id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downloadFile(resource:any){
  return httpResource().get(`${resource}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
  });

}

export function deleteholidayEvent(payload:number){
  return httpPlannerClient().delete(`${process.env.VUE_APP_BASE_URL}${API_CONST.GET_ALL_HOLIDAYS}/${payload}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };  
  });
}

export function getUserInformation(payload:any){
  return httpPlannerClient().get(`${API_CONST.USER_INFO}${API_CONST.USER_ID}${payload.userId}&${API_CONST.USER_PROGRAMID}${payload.programId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };  
  });
}
export function postUserInformation(payload:any){
  return httpPlannerClient().post(`${API_CONST.USER_INFO}`,payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}